import {
    Message,
    useMessageService
} from "../hooks/useMessageService";
import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import {
    MessageListItem,
    ErrorContainer, MessageList, WarningContainer, SuccessContainer, InfoContainer
} from "../styledcomponents/AlertStyledComponents";

export const CloseMessageButton = styled.span`
  cursor: pointer;
  opacity: 1;
  &:hover {
    opacity: 1;
  }
`

const Messages = styled.div`
  grid-area: messages;
`

type MessageProps = {
    messages : Message[]
}

function MessageDisplay(props : MessageProps) {

    return (
        <MessageList>
            {
                props.messages.map((message : Message) => {
                    if (message.content) { return <li key={message.id} style={{listStyleType : 'none'}}>{message.content}</li> }

                    return <MessageListItem key={message.id}>{message.text}</MessageListItem>
                })
            }
        </MessageList>
    )
}

export default function MessageContainer() {
    const messageService = useMessageService();

    const errorMessages   = messageService.messages.filter((message : Message) => { return message.type === 'error' });
    const successMessages = messageService.messages.filter((message : Message) => { return message.type === 'success' });
    const warningMessages = messageService.messages.filter((message : Message) => { return message.type === 'warning' });
    const infoMessages    = messageService.messages.filter((message : Message) => { return message.type === 'info' });

    const messageDivRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (messageDivRef.current && (errorMessages.length > 0 || successMessages.length > 0 || warningMessages.length > 0 || infoMessages.length > 0)) {
            messageDivRef.current.scrollIntoView({behavior: 'smooth'})
        }
    }, [errorMessages, successMessages, warningMessages, infoMessages])

    return (
        <Messages ref={messageDivRef}>
            {
                errorMessages.length > 0 &&
                <ErrorContainer>
                    <MessageDisplay messages={errorMessages} />
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('error')}>X</CloseMessageButton>
                </ErrorContainer>
            }
            {
                successMessages.length > 0 &&
                <SuccessContainer>
                    <MessageDisplay messages={successMessages} />
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('success')}>X</CloseMessageButton>
                </SuccessContainer>
            }
            {
                warningMessages.length > 0 &&
                <WarningContainer>
                    <MessageDisplay messages={warningMessages} />
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('warning')}>X</CloseMessageButton>
                </WarningContainer>
            }
            {
                infoMessages.length > 0 &&
                <InfoContainer>
                    <MessageDisplay messages={infoMessages} />
                    <CloseMessageButton onClick={() => messageService.removeMessageByType('info')}>X</CloseMessageButton>
                </InfoContainer>
            }
        </Messages>
    )
};